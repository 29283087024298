import React from "react"
import TabNavigation from "../../../components/TabNavigation"
import LayoutBasic from "../../../components/LayoutBasic"
import BasicText from "../../../components/BasicText"
import Info2column from "../../../components/Info2column"
import OverflowFrame from "../../../components/OverflowFrame"
import css from "../stylesheets/basicInformation.module.scss"
import Seo from "../../../components/Seo"
import CenterContentLayout from "../../../components/CenterContentLayout"

function Kaigo(props) {
  return (
    <div>
      <Seo title={`ABOUT KAIGO | BASIC INFORMATION`} />

      <LayoutBasic lang={`ja`}>
        <CenterContentLayout>
          <BasicText>
            <h1>BASIC INFORMATION</h1>
            <p>
              日本はどんな国？ 日本で介護の仕事をするには？
              <br />
              日本の基本的な情報や、介護の仕事をするための4つの在留資格などを紹介します。
            </p>
          </BasicText>
        </CenterContentLayout>

        <CenterContentLayout spFit={true}>
          <TabNavigation
            links={[
              { title: `ABOUT JAPAN`, link: `/ja/basic-information/` },
              {
                title: `ABOUT KAIGO`,
                link: `/ja/basic-information/kaigo/`,
                current: true,
              },
            ]}
          />
        </CenterContentLayout>
        <CenterContentLayout>
          <BasicText>
            <h2 className={css.BasicInfoH2}>ABOUT KAIGO</h2>
          </BasicText>
        </CenterContentLayout>

        <CenterContentLayout spFit={true}>
          <Info2column
            id={`section-1`}
            noBorder={true}
            rightWithSpPadding={true}
          >
            <div>
              <img
                src={`/assets/images/basic_info/basic_info_facility.svg`}
                alt="介護給付費"
                width={600}
                height={400}
              />
            </div>
            <div className={css.BasicInfoSummary}>
              <hr />
              <h3>
                日本の介護施設と介護給付費<sup>※</sup>
              </h3>
              <dl className={css.BasicInfoSummaryDlBlock}>
                <dt>介護保険施設数:</dt>
                <dd>13,409施設</dd>
              </dl>
              <dl className={css.BasicInfoSummaryDlBlock}>
                <dt>介護サービス事業所数:</dt>
                <dd>363,452事業所</dd>
              </dl>
              施設数は、特別養護老人ホームと、介護老人保健施設と、介護療養型医療施設をあわせた数。事業所数は、介護保険施設以外の、すべての介護サービス事業所をあわせた数です。これらの数は年々増えています。また、介護サービスの利用量を表す介護給付費も年々増えています。
              <div className={`mt20`}>
                <ul className={`notes`}>
                  <li>
                    <i>※</i>
                    <span>
                      厚生労働省「平成29年介護サービス施設・事業所調査の概況」（2018年）、「介護保険事業状況報告（年報）」より。
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </Info2column>
        </CenterContentLayout>
        <CenterContentLayout>
          <div id={`section-2`} className={css.BasicInfoKaigoSystem}>
            <BasicText>
              <h3>介護の在留資格</h3>
              <h4>4つの在留資格制度</h4>
              <p>
                介護の仕事をするには、4つの在留資格があります。2008年にはじまった「EPA」（Economic
                Partnership
                Agreement〈経済連携協定〉）。2017年にスタートした「介護」と「技能実習」、2019年より「特定技能」がはじまりました。
              </p>

              <OverflowFrame>
                <table className={css.BasicStickyTable}>
                  <thead>
                    <tr>
                      <th>
                        <i></i>
                      </th>
                      <th>
                        <i>EPA</i>
                      </th>
                      <th>
                        <i>介護</i>
                      </th>
                      <th>
                        <i>技能実習</i>
                      </th>
                      <th>
                        <i>特定技能</i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>
                        <i>在留資格</i>
                      </th>
                      <td>
                        <i>「特定活動」</i>
                      </td>
                      <td>
                        <i>
                          「介護」<sup>※4</sup>
                        </i>
                      </td>
                      <td>
                        <i>
                          「技能実習1〜3号」<sup>※7</sup>
                        </i>
                      </td>
                      <td>
                        <i>「特定技能1号」</i>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <i>制度の目的</i>
                      </th>
                      <td>
                        <i>
                          介護福祉士の国家資格取得を目的とした受け入れと、国際連携の強化
                        </i>
                      </td>
                      <td>
                        <i>専門的・技術的分野への外国人労働者の受け入れ</i>
                      </td>
                      <td>
                        <i>日本から相手国への技能移転</i>
                      </td>
                      <td>
                        <i>
                          人手不足対応のための一定の専門性・技能をもつ外国人の受け入れ
                        </i>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <i>実施している国</i>
                      </th>
                      <td>
                        <i>インドネシア・フィリピン・ベトナム</i>
                      </td>
                      <td>
                        <i>制限なし</i>
                      </td>
                      <td>
                        <i>制限なし</i>
                      </td>
                      <td>
                        <i>制限なし</i>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <i>日本に滞在できる期間</i>
                      </th>
                      <td>
                        <i>4年（資格を取れば制限なし）</i>
                      </td>
                      <td>
                        <i>制限なし</i>
                      </td>
                      <td>
                        <i>
                          最長5年 <sup>※8</sup>
                        </i>
                      </td>
                      <td>
                        <i>
                          最長5年 <sup>※8</sup>
                        </i>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <i>一緒に行ける家族</i>
                      </th>
                      <td>
                        <i>
                          家族（配偶者・子ども）<sup>※1</sup>
                        </i>
                      </td>
                      <td>
                        <i>家族（配偶者・子ども）</i>
                      </td>
                      <td>
                        <i>なし</i>
                      </td>
                      <td>
                        <i>なし</i>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <i>日本語能力</i>
                      </th>
                      <td>
                        <i>
                          N3 <sup>※2</sup>
                        </i>
                      </td>
                      <td>
                        <i>
                          N2 <sup>※5</sup>
                        </i>
                      </td>
                      <td>
                        <i>N4</i>
                      </td>
                      <td>
                        <i>
                          CFER A2、介護の言葉がわかる <sup>※10</sup>
                        </i>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <i>母国での介護知識・経験</i>
                      </th>
                      <td>
                        <i>看護学校の卒業生 or 母国で介護士</i>
                      </td>
                      <td>
                        <i>個人による</i>
                      </td>
                      <td>
                        <i>監理団体による</i>
                      </td>
                      <td>
                        <i>
                          一定の知識・技能がある <sup>※11</sup>
                        </i>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <i>介護福祉士国家試験の受験義務</i>
                      </th>
                      <td>
                        <i>
                          あり <sup>※3</sup>
                        </i>
                      </td>
                      <td>
                        <i>
                          あり <sup>※6</sup>
                        </i>
                      </td>
                      <td>
                        <i>
                          なし <sup>※9</sup>
                        </i>
                      </td>
                      <td>
                        <i>
                          なし <sup>※9</sup>
                        </i>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <i>受け入れ調整・支援機関</i>
                      </th>
                      <td>
                        <i>JICWELS（公益社団法人国際厚生事業団）</i>
                      </td>
                      <td>
                        <i>なし</i>
                      </td>
                      <td>
                        <i>監理団体</i>
                      </td>
                      <td>
                        <i>登録支援機関</i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </OverflowFrame>

              <ul className={`notes`}>
                <li>
                  <i>※1</i>
                  <span>介護福祉士の資格を取れた場合。</span>
                </li>
                <li>
                  <i>※2</i>
                  <span>
                    フィリピンはN5以上、インドネシアはN4以上、ベトナムはN3以上。
                  </span>
                </li>
                <li>
                  <i>※3</i>
                  <span>
                    不合格でも一定点数以上を取れていれば、1年間滞在を延ばすことができる。
                  </span>
                </li>
                <li>
                  <i>※4</i>
                  <span>介護福祉士の資格を取る前は、「留学」。</span>
                </li>
                <li>
                  <i>※5</i>
                  <span>一部の養成学校はN2以上。</span>
                </li>
                <li>
                  <i>※6</i>
                  <span>
                    2017年4月〜2022年3月の卒業者は、5年以内に国家試験に合格するか、卒業後5年間介護の仕事をすると、介護福祉士の資格を取ることができる。
                  </span>
                </li>
                <li>
                  <i>※7</i>
                  <span>
                    1年目は「技能実習1号」、2〜3年目は「技能実習2号」、4〜5年目は「技能実習3号」。
                  </span>
                </li>
                <li>
                  <i>※8</i>
                  <span>
                    介護福祉士の資格を取れば、在留資格を「介護」に変更して、在留期間に制限なく働くことができる。3年目まで修了した技能実習生は、「特定技能1号」の試験が免除される（在留資格を変更した場合、技能実習と特定技能をあわせて最長10年）。
                  </span>
                </li>
                <li>
                  <i>※9</i>
                  <span>
                    介護福祉士の資格を取れば、在留資格を「介護」に変更できる。
                  </span>
                </li>
                <li>
                  <i>※10</i>
                  <span>
                    独立行政法人国際交流基金の「日本語基礎テスト」と、厚生労働省の「介護日本語評価試験」に合格することが必要。日本語能力試験がN4以上の場合は「日本語基礎テスト」は免除。
                  </span>
                </li>
                <li>
                  <i>※11</i>
                  <span>
                    厚生労働省の「介護技能評価試験」に合格することが必要。
                  </span>
                </li>
              </ul>
              <a
                className={`mb60`}
                href={`/assets/images/basic_info/basic_info_kaigo_4type_ja.pdf`}
                target={`_blank`}
              >
                PDF DOWNLOAD (110KB)
              </a>

              <h4>働くまでの流れ</h4>
              <p>
                在留資格の種類によって、介護の施設で働くまでの流れが変わります。「EPA」と「介護」は、介護福祉士の国家資格を取ると在留期間の制限なく日本で働くことができ、「技能実習」と「特定技能」は帰国して、自分の国で技能をいかすことができます。
              </p>

              <OverflowFrame>
                <img
                  src={`/assets/images/basic_info/basic_info_kaigo_flow_ja.png`}
                  alt="働くまでの流れ"
                />
              </OverflowFrame>

              <ul className={`notes`}>
                <li>
                  <i>※1</i>
                  <span>
                    2017年4月から2022年3月までの卒業者は、卒業後5年以内に国家試験に合格するか、卒業してから5年間介護の仕事をすればよい。
                  </span>
                </li>
                <li>
                  <i>※2</i>
                  <span>点線の枠内は、今後できる予定のコース。</span>
                </li>
                <li>
                  <i>※3</i>
                  <span>
                    4年間、EPA介護福祉士候補者として適切に学び、働いた場合は、特定技能１号になるために必要な技能試験や日本語能力試験が免除される。
                  </span>
                </li>
              </ul>
              <a
                href={`/assets/images/basic_info/basic_info_kaigo_flow_ja.pdf`}
                target={`_blank`}
              >
                PDF DOWNLOAD (89KB)
              </a>
            </BasicText>
          </div>
        </CenterContentLayout>
      </LayoutBasic>
    </div>
  )
}
export default Kaigo
